define("discourse/plugins/discourse-legal-tools/discourse/connectors/admin-user-controls-after/download-all-wrapper", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "discourse/lib/export-csv", "@ember/service"], function (_exports, _component, _object, _tracking, _exportCsv, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let UserExportComponent = _exports.default = (_class = class UserExportComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "dialog", _descriptor3, this);
      _initializerDefineProperty(this, "showAdminUserExport", _descriptor4, this);
      this.initializeComponent();
    }
    initializeComponent() {
      const setting = this.siteSettings.legal_extended_user_download_admin;
      const user = this.currentUser;
      this.showAdminUserExport = this.calculateAllowed(setting, user);
    }
    calculateAllowed(setting, user) {
      switch (setting) {
        case 'disabled':
          return false;
        case 'admins_only':
          return user.admin;
        case 'admins_and_staff':
          return user.staff;
        default:
          return false;
      }
    }
    exportAdminUserArchive(user) {
      this.dialog.confirm({
        message: I18n.t("user.download_archive.confirm_all_admin", {
          username: user.username
        }),
        didConfirm: () => {
          (0, _exportCsv.exportEntity)('admin_user_archive', {
            user_id: user.id
          });
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showAdminUserExport", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "exportAdminUserArchive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "exportAdminUserArchive"), _class.prototype)), _class);
});